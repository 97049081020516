import React, { Component } from 'react';
import { Affix } from 'antd';
import RetailLink from '../commons/retailLink/RetailLink';
import { connect } from 'react-redux';
import { getWindowSize } from '../../reducers/event/eventReducer';
import './Menu.less'
import { withRouter } from 'react-router-dom';
import Utils from '../cisCommon/utils'
import { getNewsAnnoucementsList } from './../../services/cis';


class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            searchCollapsed: true,
            isCookieNotificationOpen: false,
            userInfo: Utils.getUserInfo(),
            newsList: []
        }
        this.toggleBtn = this.toggleBtn.bind(this);
        this.hide = this.hide.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.collapseHandler = this.collapseHandler.bind(this);
        this.searchHide = this.searchHide.bind(this);
        this.currentUserType = JSON.parse(localStorage.getItem('userTypeRegionName'))
    }


    initNews = async () => {
        const { regionId, investorTypeId } = this.state.userInfo
        const news = await getNewsAnnoucementsList({ regionId, investorTypeId })
        if (news.status === 200) {
            this.setState({
                newsList: news.list || []
            })
        }
    }


    componentDidMount() {
        this.initNews()
    }

    // componentDidUpdate(preProps,preStates){

    // }

    toggleBtn() {
        this.setState({ ...this.state, collapsed: !this.state.collapsed });
    }
    onWebSiteClick() {
        window.scrollTo(0, 0);
    }
    hide() {
        window.$('#menuCollapse').collapse('hide');
        this.toggleBtn();
    }
    toggleSearch() {
        if (window.location.pathname === '/universal-search') {
            document.getElementById('searchBarInput').focus()
        } else {
            this.setState({ searchCollapsed: !this.state.searchCollapsed });
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }

    collapseHandler(newState) {
        this.setState({ searchCollapsed: newState });
    }
    searchHide() {
        this.setState({ searchCollapsed: true });
    }
    clickMobileLogo = () => {
        window.$('#menuCollapse').collapse('hide');
        this.setState({ searchCollapsed: true, collapsed: true });
    }
    toggleCookieNotification = (open) => {
        this.setState({
            isCookieNotificationOpen: open
        })
    }
    desktopMenu = () => {
        const { showMenu } = this.props
        if (!showMenu) return null
        const { regionId, investorTypeId } = Utils.getUserInfo()
        const jumpHref = "/citicis/login-auth"
        return (

            <div className="menuBar">
                <div className="container container-custom">
                    <ul className="menuList" onClick={this.searchHide}>
                        <li title="Home">
                            <RetailLink menuNode={`cis-home/${regionId}/${investorTypeId}`}>Home</RetailLink>
                        </li>
                        <li title="Indices">
                            <RetailLink menuNode={`indices/${regionId}/${investorTypeId}`}>Browse Indices</RetailLink>
                        </li>
                        {
                            this.state.newsList.length > 0 &&
                            <li title="News & Announcements">
                                <RetailLink menuNode={`news/${regionId}/${investorTypeId}`}>News & Announcements</RetailLink>
                            </li>
                        }


                        <li title="AboutCIS">
                            <RetailLink menuNode={`cis-about/${regionId}/${investorTypeId}`}>About CIS</RetailLink>
                        </li>
                        <li title="Governance">
                            <RetailLink menuNode={`cis-governance/${regionId}/${investorTypeId}`}>Governance</RetailLink>
                        </li>

                    </ul>
                    <span className="searchItem" >
                        <p className="cisText">
                            {this.currentUserType && this.currentUserType.region} &nbsp;
                        <RetailLink className="cisLink" menuNode="cis-home">Change Profile</RetailLink>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                                className="cisLink"
                                href={jumpHref}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Institutional Login
                        </a>
                        </p>
                    </span>
                </div>
            </div>
        )
    }
    // shouldComponentUpdate = (nextProps, nextState) => {
    //   if(
    //       (nextState.collapsed!==this.state.collapsed)
    //       ||(nextState.isCookieNotificationOpen!==this.state.isCookieNotificationOpen)
    //       ||(nextState.searchCollapsed!==this.state.searchCollapsed)
    //       ||(nextProps.windowSize!==this.props.windowSize)
    //     ){
    //       return true;
    //   }
    // }



    render() {
        const { searchCollapsed, collapsed, isCookieNotificationOpen } = this.state;
        const { windowSize } = this.props;
        const { regionId, investorTypeId } = Utils.getUserInfo()

        if (!collapsed) { window.scrollTo(0, 0); console.log('sssss') }
        return (
            <div className="Menu" >
                <Affix>
                    {this.desktopMenu()}
                </Affix>
                <div id="menuCollapse" className="collapse menuCollapses">
                    <ul className="MenuList" onClick={this.hide}>
                        <li className="menuItem mainMenu"><RetailLink menuNode="cis-home">Home</RetailLink></li>
                        <li className="menuItem mainMenu"><RetailLink menuNode="indices">Browse Indices</RetailLink></li>
                        {
                            this.state.newsList.length > 0 &&
                            <li
                                className="menuItem mainMenu"
                                title="News & Announcements"
                            >
                                <RetailLink menuNode={`news/${regionId}/${investorTypeId}`}>News & Announcements</RetailLink>
                            </li>
                        }
                        <li className="menuItem mainMenu"><RetailLink menuNode="cis-governance">Governance</RetailLink></li>
                        <li className="menuItem"><RetailLink menuNode="cis-about">About Us</RetailLink></li>
                        <li className="menuItem"><RetailLink menuNode="cis-contact">Contact Us</RetailLink></li>
                        <li className="menuItem"><RetailLink menuNode="cis-help">Help</RetailLink></li>
                    </ul>
                </div>
            </div>
        );
    }

}
const mapStateToProps = state => ({
    windowSize: getWindowSize(state),
});

const mapDispatchToProps = {
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));