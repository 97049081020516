import React, { useReducer,useEffect,useState } from 'react'
import Login from './login'
import { getListByInvestorType } from '../../services/cis';
import {withRouter} from 'react-router-dom';
import Page from '../commons/page/Page';
import { getPageByName } from './../../services/page';
import './loginContainer.less';
import Utils from '../cisCommon/utils'
import Footer from "../../cisDoc/views/footer";

const { GetAccess } = window["citicms-repo"] || { GetAccess: null };
// const detailPathname = localStorage.getItem('detailIndicePathname') && localStorage.getItem('detailIndicePathname').split('/')

const config = {
  title: "More Information",
  description:
    "Financial Intermediaries and Wealth Management Professionals can find more information about Citi's range of products and services",
  actionText: "Learn More",
  url: "https://www.crossassetsolutions.citi.com/",
  isSmallButton: true,
};

let transferSelection = function(selection) {
  let newSelection = [];
  selection.map((select) => {
    const { id, description, label } = select;
    let newSelect = {
      id: id,
      label: description,
      description: label,
    };
    newSelection.push(newSelect);
  });
  return Object.assign([], newSelection);
};

/**
 * Actions
 */
const userTypeChange = (usertype) => ({
  type: "USERTYPE_CHANGE",
  usertype,
});

const regionTypeChange = (region) => ({
  type: "REGIONTYPE_CHANGE",
  region,
});

const clearUsertypeRegion = () => ({
  type: "CLEAR_USERTYPE_REGION",
});

const receiveUsertypeRegion = (json) => ({
  type: "RECEIVE_USERTYPE_REGION",
  usertypeList: json.investorTypeList ? json.investorTypeList : [],
  regionList: json.regionList ? json.regionList : [],
  profileRelation: json.regionAndInvestorTypePerfConfigList
    ? json.regionAndInvestorTypePerfConfigList
    : [],
});

/**
 * Initial States
 */
const initialState = {
  region: 0,
  usertype: 0,
  regionList: [],
  usertypeList: [],
  profileRelation: [],
  regionSelected: false,
  usertypeSelected: false,
};

/**
 * Reducers
 * @param {*} state
 * @param {*} action
 */

const loginReducer = (state, action) => {
  switch (action.type) {
    case "RECEIVE_USERTYPE_REGION":
      return {
        ...state,
        usertypeList: transferSelection(action.usertypeList),
        regionList: transferSelection(action.regionList),
        profileRelation: action.profileRelation,
        usertype: 2, //mock
        usertypeSelected: true, //mock
      };
    case "REGIONTYPE_CHANGE":
      return {
        ...state,
        region: action.region,
        regionSelected: action.region !== 0,
        // usertypeList: mockUpUserTypeId(state.usertypeList, state.regionList, action.region),
      };
    case "USERTYPE_CHANGE":
      return {
        ...state,
        usertype: action.usertype,
        usertypeSelected: action.usertype !== 0,
      };
    case "CLEAR_USERTYPE_REGION":
      return {
        ...state,
        regionSelected: false,
        usertypeSelected: false,
      };
    default:
      return state;
  }
};

/**
 * Component
 * @param {*} props
 */
const LoginContainer = (props) => {
  const [state, dispatch] = useReducer(loginReducer, initialState);
  const [uiRow, setUIRow] = useState({ name: "CIS-Footer", value: null });

  const getConfigedPage = async (name) => {
    try {
      const rsp = await getPageByName(name);
      if (rsp.success === 1) {
        setUIRow({
          name: "CIS-Footer",
          //   value:rsp.data
          value: null,
        });
      }
    } catch (err) {
      //server error
    }
  };

  useEffect(() => {
    getConfigedPage(uiRow.name);
    try {
      const {
        RETAIL: { trackPageViewByPageName },
        location: { pathname },
      } = window;
      trackPageViewByPageName(pathname);
    } catch (err) {}
  }, [uiRow.name]);

  const actions = {
    userTypeChange: (usertype) => dispatch(userTypeChange(usertype)),
    regionTypeChange: (region) => dispatch(regionTypeChange(region)),
    clearUsertypeRegion: () => dispatch(clearUsertypeRegion()),
    fetchUsertypeRegion: async () => {
      const rsp = await getListByInvestorType();
      dispatch(receiveUsertypeRegion(rsp));
    },
  };

  return (
    <React.Fragment>
      <div className="blueberry cis-page full-width">
        <Login {...state} actions={actions} />
        {GetAccess && <GetAccess {...config} />}
      </div>
      {uiRow.value && <Page page={uiRow.value.uiRows}></Page>}
      <Footer />
    </React.Fragment>
  );
};

export default withRouter(LoginContainer) 
