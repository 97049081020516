import React from 'react';
import $ from 'jquery/dist/jquery.min';
// import Promise from 'promise';
import Clone from 'clone';
import SvgGenerator from '../component/svgGenerator';

export default {

	defineFetch: function (url, method, parms) {
		let fullUrl = this.transferUrl(url);
		let promise = new Promise(function (resolve, reject) {
			$.ajax({
				method: method,
				url: fullUrl,
				timeout: 600000,
				contentType: 'application/json',
				dataType: 'json',
				data: (!parms ? {} : parms),
			}).done(resolve).fail(reject);
		});
		return promise;
	},

	fetchFiles: function (url, method, parms) {

		let fullUrl = this.transferUrl(url);
		let promise = new Promise(function (resolve, reject) {
			$.ajax({
				method: method,
				url: fullUrl,
				cache: false,
				processData: false,
				contentType: false,
				data: (!parms ? {} : parms),
			}).done(resolve).fail(reject);
		});
		return promise;

	},

	transferUrl: function (url) {
		let fullUrl = url; //this.dynamicallyUpdateURL(url);
		let pathName = window.location.pathname;
		if (pathName && url && url !== '/portal-auth/logout') {
			let currentHref = pathName.split('/')[1];
			fullUrl = '/'.concat(currentHref, '', fullUrl);
		}
		return fullUrl;
	},

	/**
	* This method is to help back-end service refactoring, ajax call can dynamically update the url.
	*@param {string} url - This is the url to be update.
	*/
	dynamicallyUpdateURL: function (url) {
		let urlArr = url.split('/');
		urlArr.splice(3, 0, 'v1');
		return urlArr.join('/');
	},

	isFunc(funcName) {
		let isFunc = false;
		if (funcName && typeof funcName === 'function') {
			isFunc = true;
		}
		return isFunc;
	},

	transferDateStringToTimeStamp(dateString) {
		let temp_date = new Date(dateString);
		return temp_date.valueOf();
	},

	arraySortByAttr(preSortArray, mainAttr, subAttr, direction, specialColums) {
		let sortedArray = Object.assign([], preSortArray);
		if (sortedArray) {
			sortedArray.sort((a, b) => {
				let checkIdetifer = false;
				let [aMainAttr = '', bMainAttr = '', aSubAttr = '', bSubAttr = ''] = [a[mainAttr], b[mainAttr], a[subAttr], b[subAttr]];
				if (specialColums.indexOf(mainAttr) !== -1) {
					aMainAttr = this.transferDateStringToTimeStamp(aMainAttr);
					bMainAttr = this.transferDateStringToTimeStamp(bMainAttr);
				}
				if (!isNaN(aMainAttr) && !isNaN(bMainAttr)) {
					aMainAttr = Number(aMainAttr);
					bMainAttr = Number(bMainAttr);
					checkIdetifer = true;
				}
				if (!isNaN(aSubAttr) && !isNaN(bSubAttr)) {
					aSubAttr = Number(aSubAttr);
					bSubAttr = Number(bSubAttr);
				}
				let result = 0;
				if (checkIdetifer) {
					result = (aMainAttr < bMainAttr) ? -1 : ((aMainAttr > bMainAttr) ? 1 : (aSubAttr > bSubAttr ? 1 : -1));
				} else {
					result = ((aMainAttr || '').toUpperCase() < (bMainAttr || '').toUpperCase()) ? -1 : (((aMainAttr || '').toUpperCase() > (bMainAttr || '').toUpperCase()) ? 1 : (aSubAttr > bSubAttr ? 1 : -1));
				}
				return result * direction;
			});
		}
		return Object.assign([], sortedArray);
	},

	addCheckBoxToListSingle(list, selectedItem, attr) {
		const editList = Clone(list);
		let returnList = [];
		if (editList && editList.length > 0) {
			editList.map((item, index) => {
				item.checkBox = !!selectedItem && selectedItem === item[attr];
				returnList.push(item);
			});
		}
		return returnList;
	},

	addCheckBoxToListMulti(list, selectedList, attr) {
		const editList = Clone(list);
		let returnList = [];
		if (editList && editList.length > 0) {
			editList.map((item, index) => {
				let checked = false;
				item.checkBox = false;
				if (selectedList && selectedList.length > 0) {
					selectedList.map((selectedItem, index) => {
						checked = (selectedItem === item[attr]);
						if (checked) {
							item.checkBox = true;
						}
					});
				}
				returnList.push(item);
			});
		}
		return returnList;
	},

	removeOneItemForArray(itemArray, item) {
		let temArray = itemArray;
		if (Array.isArray(temArray) && temArray.length > 0) {
			if (!this.checkNullUndefined(item)) {
				let position = -1;
				temArray.map((items, index) => {
					if (items === item) {
						position = index;
					}
				});
				if (position > -1) {
					temArray.splice(position, 1);
				}
			}
		}
		return temArray;
	},

	checkNullUndefined(value) {
		let nullOrUndefined = false;
		if (value === null || value === undefined || value === '') {
			nullOrUndefined = true;
		}
		return nullOrUndefined;
	},

	getKeywordFromUrl() {
		let locationKeyWord = '';
		let locationSplitedArray = window.location.href.split('?');
		if (locationSplitedArray && locationSplitedArray.length > 0) {
			locationKeyWord = locationSplitedArray[locationSplitedArray.length - 1];
		}
		return locationKeyWord;
	},

	getIconByType(fileType, size) {
		let fileTypeIcon = '';
		if (fileType === 'pdf') {
			fileTypeIcon = 'by-custom-color:file-pdf-red';
		} else if (fileType === 'xls' || fileType === 'xlsx') {
			fileTypeIcon = 'by-custom-color:file-excel-green';
		} else if (fileType === 'docx' || fileType === 'doc') {
			fileTypeIcon = 'by-custom-color:file-word-doc-blue';
		} else {
			fileTypeIcon = 'by-custom-color:document-generic-file-gray';
		}
		return (
			<SvgGenerator type={fileTypeIcon} svgClass='by-svg-icon' svgSize={size} style={{ verticalAlign: 'middle' }} />
		);
	},

	getCurrentYear() {
		const currentDate = new Date();
		return currentDate.getFullYear();
	}
};