import React from 'react';
import SvgGenerator from '../component/svgGenerator';
import Utils from '../utils/utils';

export default class Footer extends React.Component {
	generateUrlPrefix() {
		let locationKeyWord = '';
		let locationSplitedArray = window.location.href.split('?');
		if (locationSplitedArray && locationSplitedArray.length > 0) {
			locationKeyWord = locationSplitedArray[0];
		}
		return locationKeyWord;

	}
	render() {
		const urlPrefx = this.generateUrlPrefix();

		const newPrivacyUrl = (urlPrefx && urlPrefx.length > 0) ? (urlPrefx + '?privacy') : '';
		const newTermsUrl = (urlPrefx && urlPrefx.length > 0) ? (urlPrefx + '?terms') : '';
		const newDisclaimerUrl = (urlPrefx && urlPrefx.length > 0) ? (urlPrefx + '?disclaimer') : '';
		const cookiepoilcyUrl = 'https://www.citigroup.com/global/institutional-clients/cookiepolicy'

		const currentYear = Utils.getCurrentYear();

		return (
			<div className='cis-footer'>
				<ul className='text-decoration-a' >
					<li><a href='http://www.citigroup.com' target='_blank' rel='noopener noreferrer'>citigroup.com</a></li>
					<li><a href="/cis-privacy" target='_blank' rel='noopener noreferrer'>PRIVACY</a></li>
					<li><a href="/cis-disclaimer" target='_blank' rel='noopener noreferrer'>DISCLAIMER</a></li>
					<li><a href="/cis-terms-conditions" target='_blank' rel='noopener noreferrer'>TERMS & CONDITIONS</a></li>					
					<li><a href={cookiepoilcyUrl} target='_blank' rel='noopener noreferrer'>COOKIE POLICY</a></li>
					<li><a id="manage-cookie-preferences-footer-link" style={{ display: 'none' }}> COOKIE PREFERENCES</a> </li>

				</ul>
				<ul>
					<li>
						<SvgGenerator type='by-logo:logo-citi-2-color' />
						<div className='cis-footer-bottomContent'>Citigroup.com is the global source of information and access to financial services provided by the Citi family of companies.</div>
					</li>
					<li><div>Copyright &copy; 2003 - {currentYear} Citigroup Inc.</div></li>
				</ul>
			</div>
		);
	}
}
