import React from 'react'
import { MENU_CONFIG } from './menuConfig';
import { Route } from 'react-router-dom'
import ConfiguredPage from '../pages/configuredPage/ConfiguredPage';
import Utils from '../components/cisCommon/utils';

const menus = Object.keys(MENU_CONFIG)

const routes = menus.map((menuItem,index) => {


    return (
        <Route key={index}
            exact
            path={MENU_CONFIG[menuItem].path}
            render={(props) => {
                let pageName = MENU_CONFIG[menuItem].pageName,
                checkUrl = MENU_CONFIG[menuItem].checkUrl
                if (props.match.path.indexOf(':pageName') >= 0){
                    pageName = props.match.params.pageName
                }
                try {
                  const { region, userType } = Utils.getInfoName();
                  const {
                    RETAIL: { trackPageViewByPageName },
                    location: { pathname },
                  } = window;
                  trackPageViewByPageName(
                    `/${pathname.split("/")[1]}${region}${userType}`
                  );
                } catch (err) {}
                if (pageName === "CIS-Footer") {
                  return null;
                }
                return <ConfiguredPage {...props} key={pageName} pageName={pageName} checkUrl={checkUrl}/>
            }}
        >
        </Route>
    )

}

)

export default routes
